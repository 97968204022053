import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/shared/config.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  menus:any = [];
  header:any;

  constructor(private configService:ConfigService) { }

  ngOnInit(): void {
    this.configService.getdata().subscribe(
      data=>{
        this.menus = this.getMenus(data);
        this.header = this.getHeader(data); 
      }
    )
  }


  getMenus(item:any){
      return item.menu
  }

  getHeader(item:any){
    return item.header
  }


}
