<section id="about-banner" class="clearfix">
    <div class="container">
        <div class="about-info">
            <h2>Services</h2>
            <h3>Data Warehousing </h3>
        </div>
    </div>
</section>

<section id="service">
    <div class="container">
        <div class="row about-extra services">
            <div class="col-lg-5 wow fadeInUp order-1 order-lg-2">
                <img src="assets/img/about-extra-2.svg" class="img-fluid" alt="">
            </div>

            <div class="col-lg-7 wow fadeInUp pt-4 pt-lg-0 order-2 order-lg-1">
                <h4>Data Warehousing Services</h4>
                <p>
                    Modern Businesses today are focused more on mitigating their competition in
                    order to survive. The customer demands are such that it makes businesses adopt
                    every technology/tool to meet their objectives. Businesses today gather a lot of
                    data during their operations and the same if gathered in a structured manner,
                    stored smartly and analyzed intelligently, the resulting information can be
                    applied to enhance one’s business. Data Warehousing involves managing
                    complicated data by Architecting and Designing high level data systems,
                    providing for effective analysis. Our DWH team works tirelessly to help our
                    esteemed clientele.<br><br><br><br></p>
            </div>
        </div>
    </div>
</section>