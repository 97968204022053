<!--==========================
  Header
  ============================-->
<header id="header" class="fixed-top">
  <div class="container">

    <div class="logo float-left">
      <!-- Uncomment below if you prefer to use an image logo -->
      <!-- <h1 class="text-light"><a href="#header"><span>NewBiz</span></a></h1> -->
      <a href="#" class="scrollto"><img [src]="header.logo" alt="" class="img-fluid"></a>
    </div>

    <nav class="main-nav float-right d-none d-lg-block ">
      <ul>
        <!-- <li *ngFor="let menu of menus" [ngClass]="{'drop-down' : menu.subMenu}"><a href="">{{menu.menuName}}</a> -->
        <li><a href="#">Home</a></li>
        <li><a routerLink="/about">About Us</a></li>
        <li class="drop-down "><a routerLink="#">Services</a>
          <ul>
            <li><a routerLink="/services">Staff Augmentation Services</a></li>
            <li><a routerLink="/service2">IT ProfessionalL Services</a></li>
            <li><a routerLink="/service3">Data Warehousing Services</a></li>
            <li><a routerLink="/service4">Digital Marketing</a></li>
            <li><a routerLink="/service5">Testing Services</a></li>

          </ul>
        </li>
        <li><a routerLink="/contact">Contact Us</a></li>
        <!-- <ul>
        <li *ngFor="let sub of menu.subMenu"><a href="home">{{sub.menuName}}</a></li> -->

        <!-- <li class="drop-down"><a href="#">Drop Down 2</a>
            <ul>
              <li><a href="#">Deep Drop Down 1</a></li>
              <li><a href="#">Deep Drop Down 2</a></li>
              <li><a href="#">Deep Drop Down 3</a></li>
              <li><a href="#">Deep Drop Down 4</a></li>
              <li><a href="#">Deep Drop Down 5</a></li>
            </ul>
          </li> -->
        <!-- </ul> -->
        <!-- </li> -->
        <!-- <li><a href="#about">About Us</a></li>
          <li><a href="#services">Services</a></li>
          <li><a href="#portfolio">Portfolio</a></li>
          <li><a href="#team">Team</a></li> -->
        <!-- <li class="drop-down"><a href="">Drop Down</a>
            <ul>
              <li><a href="#">Drop Down 1</a></li>
              <li class="drop-down"><a href="#">Drop Down 2</a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li>
            </ul>
          </li> -->
      </ul>
    </nav><!-- .main-nav -->

  </div>
</header><!-- #header -->