import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgwWowService } from 'ngx-wow';
import 'owl.carousel';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/shared/config.service';
import { About } from 'src/app/shared/model/model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {

  testimonial: any;
  whyChooseus: any;
  aboutUs: About[] = [];
  team: any;
  services: any;
  client: any;

  constructor(private wowService: NgwWowService, private configService: ConfigService) {
    this.wowService.init();



    this.configService.getdata().subscribe(
      data => {
        this.testimonial = this.getTestimonial(data);
        this.whyChooseus = this.whyChooseUs(data);
        this.aboutUs = this.aboutData(data);
        this.team = this.getTeam(data);
        this.services = this.getServices(data);
        this.client = this.getClient(data)
        console.log(this.team)
      }
    )
  }

  getTestimonial(data: any) {
    return data.testimonial
  }

  whyChooseUs(item: any) {
    return item.whyChooseUs
  }

  aboutData(item: any) {
    return item.about
  }

  getTeam(item: any) {
    return item.team
  }

  getServices(item: any) {
    return item.services
  }
  getClient(item: any) {
    return item.client
  }

  public this: any = this;

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // Back to top button
    $('.back-to-top').click(function () {
      $('html, body').animate({ scrollTop: 0 }, 1500, 'easeInOutExpo');
      return false;
    });

    // Testimonials carousel (uses the Owl Carousel library)
    $(".testimonials-carousel").owlCarousel({
      autoplay: true,
      dots: true,
      loop: true,
      items: 1
    });

    // jQuery counterUp (used in Whu Us section)
    // $('[data-toggle="counter-up"]').counterUp({
    //   delay: 10,
    //   time: 1000
    // });

    // Porfolio isotope and filter
    // $(window).on('load', function () {
    //   var portfolioIsotope = $('.portfolio-container').isotope({
    //     itemSelector: '.portfolio-item'
    //   });
    //   $('#portfolio-flters li').on( 'click', function() {
    //     $("#portfolio-flters li").removeClass('filter-active');
    //     $(this).addClass('filter-active');

    //     portfolioIsotope.isotope({ filter: $(this).data('filter') });
    //   });
    // });

  }
}
