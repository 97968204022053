import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { AboutComponent } from './modules/about/about.component';
import { ServicesComponent } from './modules/services/services.component';
import { ContactComponent } from './modules/contact/contact.component';
import { Service2Component } from './modules/service2/service2.component';
import { Service3Component } from './modules/service3/service3.component';
import { Service4Component } from './modules/service4/service4.component';
import { Service5Component } from './modules/service5/service5.component';
const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: "home", component: HomeComponent },
  { path: "about", component: AboutComponent },
  { path: "services", component: ServicesComponent },
  { path: "service2", component: Service2Component },
  { path: "service3", component: Service3Component },
  { path: "service4", component: Service4Component },
  { path: "service5", component: Service5Component },
  { path: "contact", component: ContactComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
