<!--==========================
    Intro Section
  ============================-->
<section id="intro" class="clearfix">
  <div class="container">

    <div class="intro-img">
      <img src="assets/img/intro-img.svg" alt="" class="img-fluid">
    </div>

    <div class="intro-info wow fadeInLeft">
      <h2>Explore<br> the wide range of <br>result oriented business solutions

      </h2>
      <!-- <div>
          <a href="#about" class="btn-get-started scrollto">Get Started</a>
          <a href="#services" class="btn-services scrollto">Our Services</a>
        </div> -->
    </div>

  </div>
</section><!-- #intro -->



<main id="main">

  <!--==========================
      About Us Section
    ============================-->
  <!-- <section id="about"> -->
  <!-- <div class="container"> -->

  <!-- <header class="section-header">
        <h3>About US</h3>
        <p>Mithra a whole new perspective”, is a fast emerging technology consulting company based at Bangalore, India.
          We provide
          a diversity of consulting services bespoke to the specific staffing needs. We trust that each client is
          different in
          terms of work environment and organizational culture. We understand that the views of people are different.
          The
          challenge is matching their ideas, expectations and goals with that of the company.</p>
      </header> -->

  <!-- <div class="row d-flex align-items-center about-container" *ngFor="let about of aboutUs">

        <div class="col-lg-6 fadeInUp pt-5 pt-lg-0 content order-1"
          [ngClass]="(about.columnStyle =='left-to-right')?'order-1':'order-2'">
         <h4>{{about.heading}}</h4> -->
  <!-- <p>
            {{about.ShortBreaf}}
          </p> -->

  <!-- <div class="icon-box wow fadeInUp" *ngFor="let tile of about.gridTile; let i = index" data-wow-delay="0.2s">
            <div class="icon" *ngIf="tile.icon"><i class="{{tile.icon}}"></i></div>
            <h4 class="title" [ngStyle]="{'margin-left': (!tile.icon) ? '0' : '79px' }"><a href="">{{tile.title}}</a>
            </h4>
            <p class="description" *ngIf="tile.content">{{tile.content}}</p>
          </div>

        </div> -->

  <!-- <div class="col-lg-6  background  wow fadeInUp"
          [ngClass]="(about.columnStyle =='left-to-right')?'order-2':'order-1'">
          <img [src]="about.imageUrl" class="img-fluid" alt="">
        </div>

      </div> -->

  <!-- <div class="row about-extra">
          <div class="col-lg-6 wow fadeInUp">
            <img src="assets/img/about-extra-1.svg" class="img-fluid" alt="">
          </div>
          <div class="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
            <h4>Voluptatem dignissimos provident quasi corporis voluptates sit assumenda.</h4>
            <p>
              Ipsum in aspernatur ut possimus sint. Quia omnis est occaecati possimus ea. Quas molestiae perspiciatis occaecati qui rerum. Deleniti quod porro sed quisquam saepe. Numquam mollitia recusandae non ad at et a.
            </p>
            <p>
              Ad vitae recusandae odit possimus. Quaerat cum ipsum corrupti. Odit qui asperiores ea corporis deserunt veritatis quidem expedita perferendis. Qui rerum eligendi ex doloribus quia sit. Porro rerum eum eum.
            </p>
          </div>
        </div> -->
  <!-- 
        <div class="row about-extra">
          <div class="col-lg-6 wow fadeInUp order-1 order-lg-2">
            <img src="assets/img/about-extra-2.svg" class="img-fluid" alt="">
          </div>

          <div class="col-lg-6 wow fadeInUp pt-4 pt-lg-0 order-2 order-lg-1">
            <h4>Neque saepe temporibus repellat ea ipsum et. Id vel et quia tempora facere reprehenderit.</h4>
            <p>
             Delectus alias ut incidunt delectus nam placeat in consequatur. Sed cupiditate quia ea quis. Voluptas nemo qui aut distinctio. Cumque fugit earum est quam officiis numquam. Ducimus corporis autem at blanditiis beatae incidunt sunt. 
            </p>
            <p>
              Voluptas saepe natus quidem blanditiis. Non sunt impedit voluptas mollitia beatae. Qui esse molestias. Laudantium libero nisi vitae debitis. Dolorem cupiditate est perferendis iusto.
            </p>
            <p>
              Eum quia in. Magni quas ipsum a. Quis ex voluptatem inventore sint quia modi. Numquam est aut fuga mollitia exercitationem nam accusantium provident quia.
            </p>
          </div>
          
        </div> -->

  <!-- </div> -->
  <!-- </section> -->

  <!--==========================
      Services Section
    ============================-->
  <section id="services" class="section-bg">
    <div class="container">

      <header class="section-header">
        <h3>{{services.heading}}</h3>
        <!-- <p>{{services.subHeading}}</p> -->
      </header>

      <div class="row">
        <div class="col-md-6 col-lg-6  wow bounceInUp" data-wow-duration="1.4s">
          <div class="box">
            <div class="icon"><i class="ion-ios-analytics-outline"></i></div>
            <h4 class="title">Staff Augmentation Services</h4>
            <p class="description">A dynamic manpower is an integral component of an organization that enables
              it to achieve the targets &amp; goals. We, at Mithra understand it well, and thus,
              offer an integrated staffing solution for our valued partners....<a routerLink="/services">Read More</a>
            </p>
          </div>
        </div>


        <div class="col-md-6 col-lg-6  wow bounceInUp" data-wow-duration="1.4s">
          <div class="box">
            <div class="icon"><i class="ion-ios-analytics-outline"></i></div>
            <h4 class="title">IT ProfessionalL Services</h4>
            <p class="description">To ensure a business runs smoothly and remains ahead of the competition, one
              has to adapt to trending and new-gen technologies. Trending technologies such
              as <i>Mobile, Analytics, Cloud, IoT</i> and so on, makes it imperative to not just apply
              them...<a routerLink="/service2">Read More</a></p>
          </div>
        </div>

        <div class="col-md-6 col-lg-6  wow bounceInUp" data-wow-duration="1.4s">
          <div class="box">
            <div class="icon"><i class="ion-ios-analytics-outline"></i></div>
            <h4 class="title">Data Warehousing Services</h4>
            <p class="description">Modern Businesses today are focused more on mitigating their competition in
              order to survive. The customer demands are such that it makes businesses adopt
              every technology/tool to meet their objectives. ...<a routerLink="/service3">Read More</a></p>
          </div>
        </div>

        <div class="col-md-6 col-lg-6  wow bounceInUp" data-wow-duration="1.4s">
          <div class="box">
            <div class="icon"><i class="ion-ios-analytics-outline"></i></div>
            <h4 class="title">Digital Marketing</h4>
            <p class="description">With the onset of the digital media, it has become next to impossible for
              businesses to progress without adopting this medium, which has an expansive
              and a targeted clientele reach....<a routerLink="/service4">Read More</a></p>
          </div>
        </div>
        <div class="col-md-6 col-lg-6  wow bounceInUp" data-wow-duration="1.4s">
          <div class="box">
            <div class="icon"><i class="ion-ios-analytics-outline"></i></div>
            <h4 class="title">Testing Services</h4>
            <p class="description">The software industry is booming like anything and what makes software more reliable
              and functional
              is the various tests
              that confirms its sound operation.To make sure you make an impactful impression, it is important to
              test your newly....<a routerLink="/service5">Read More</a></p>
          </div>
        </div>
        <!-- <div class="col-md-6 col-lg-6  wow bounceInUp" data-wow-duration="1.4s">
                  <div class="box">
                    <div class="icon"><i class="ion-ios-analytics-outline"></i></div>
                    <h4 class="title">Digital Marketing</h4>
                    <p class="description">With the onset of the digital media, it has become next to impossible for
                      businesses to progress without adopting this medium, which has an expansive
                      and a targeted clientele reach....<a routerLink="/service4">Read More</a></p>
                  </div>
                </div> -->
      </div>
    </div>
  </section><!-- #services -->

  <!--==========================
      Why Us Section
    ============================-->
  <!-- <section id="why-us" class="wow fadeIn" *ngIf="!isVisible">
    <div class="container">
      <header class="section-header">
        <h3>{{whyChooseus.heading}}</h3>
        <p>{{whyChooseus.subHeading}}</p>
      </header>

      <div class="row row-eq-height justify-content-center">

        <div class="col-lg-4 mb-4" *ngFor="let item of whyChooseus.grid">
          <div class="card wow bounceInUp">
            <i class="{{item.icon}}"></i>
            <div class="card-body">
              <h5 class="card-title">{{item.title}}</h5>
              <p class="card-text">{{item.text}}</p>
              <a href="#" class="readmore">{{item.button}} </a>
            </div>
          </div>
        </div>

      </div>

      <div class="row counters">

        <div class="col-lg-3 col-6 text-center" *ngFor="let count of whyChooseus.count">
          <span>{{count.count}}</span>
          <p>{{count.details}}</p>
        </div>

      </div>

    </div>
  </section> -->

  <!--==========================
      Portfolio Section
    ============================-->
  <!-- <section id="portfolio" class="clearfix">
      <div class="container">

        <header class="section-header">
          <h3 class="section-title">Our Portfolio</h3>
        </header>

        <div class="row">
          <div class="col-lg-12">
            <ul id="portfolio-flters">
              <li data-filter="*" class="filter-active">All</li>
              <li data-filter=".filter-app">App</li>
              <li data-filter=".filter-card">Card</li>
              <li data-filter=".filter-web">Web</li>
            </ul>
          </div>
        </div>

        <div class="row portfolio-container">

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/app1.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4><a href="#">App 1</a></h4>
                <p>App</p>
                <div>
                  <a href="assets/img/portfolio/app1.jpg" data-lightbox="portfolio" data-title="App 1" class="link-preview" title="Preview"><i class="ion ion-eye"></i></a>
                  <a href="#" class="link-details" title="More Details"><i class="ion ion-android-open"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web" data-wow-delay="0.1s">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/web3.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4><a href="#">Web 3</a></h4>
                <p>Web</p>
                <div>
                  <a href="assets/img/portfolio/web3.jpg" class="link-preview" data-lightbox="portfolio" data-title="Web 3" title="Preview"><i class="ion ion-eye"></i></a>
                  <a href="#" class="link-details" title="More Details"><i class="ion ion-android-open"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app" data-wow-delay="0.2s">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/app2.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4><a href="#">App 2</a></h4>
                <p>App</p>
                <div>
                  <a href="assets/img/portfolio/app2.jpg" class="link-preview" data-lightbox="portfolio" data-title="App 2" title="Preview"><i class="ion ion-eye"></i></a>
                  <a href="#" class="link-details" title="More Details"><i class="ion ion-android-open"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-card">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/card2.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4><a href="#">Card 2</a></h4>
                <p>Card</p>
                <div>
                  <a href="assets/img/portfolio/card2.jpg" class="link-preview" data-lightbox="portfolio" data-title="Card 2" title="Preview"><i class="ion ion-eye"></i></a>
                  <a href="#" class="link-details" title="More Details"><i class="ion ion-android-open"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web" data-wow-delay="0.1s">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/web2.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4><a href="#">Web 2</a></h4>
                <p>Web</p>
                <div>
                  <a href="assets/img/portfolio/web2.jpg" class="link-preview" data-lightbox="portfolio" data-title="Web 2" title="Preview"><i class="ion ion-eye"></i></a>
                  <a href="#" class="link-details" title="More Details"><i class="ion ion-android-open"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app" data-wow-delay="0.2s">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/app3.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4><a href="#">App 3</a></h4>
                <p>App</p>
                <div>
                  <a href="assets/img/portfolio/app3.jpg" class="link-preview" data-lightbox="portfolio" data-title="App 3" title="Preview"><i class="ion ion-eye"></i></a>
                  <a href="#" class="link-details" title="More Details"><i class="ion ion-android-open"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-card">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/card1.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4><a href="#">Card 1</a></h4>
                <p>Card</p>
                <div>
                  <a href="assets/img/portfolio/card1.jpg" class="link-preview" data-lightbox="portfolio" data-title="Card 1" title="Preview"><i class="ion ion-eye"></i></a>
                  <a href="#" class="link-details" title="More Details"><i class="ion ion-android-open"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-card" data-wow-delay="0.1s">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/card3.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4><a href="#">Card 3</a></h4>
                <p>Card</p>
                <div>
                  <a href="assets/img/portfolio/card3.jpg" class="link-preview" data-lightbox="portfolio" data-title="Card 3" title="Preview"><i class="ion ion-eye"></i></a>
                  <a href="#" class="link-details" title="More Details"><i class="ion ion-android-open"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web" data-wow-delay="0.2s">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/web1.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4><a href="#">Web 1</a></h4>
                <p>Web</p>
                <div>
                  <a href="assets/img/portfolio/web1.jpg" class="link-preview" data-lightbox="portfolio" data-title="Web 1" title="Preview"><i class="ion ion-eye"></i></a>
                  <a href="#" class="link-details" title="More Details"><i class="ion ion-android-open"></i></a>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section> -->

  <!-- #portfolio -->

  <!--==========================
      Clients Section
    ============================-->
  <!-- <section id="testimonials" class="section-bg" *ngIf="!isVisible">
    <div class="container">

      <header class="section-header">
        <h3>{{testimonial.heading}}</h3>
      </header>

      <div class="row justify-content-center">
        <div class="col-lg-8">

          <div class=" testimonials-carousel wow fadeInUp">

            <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide *ngFor="let item of testimonial.grid">
                <div class="testimonial-item">
                  <img [src]="item.avatar" class="testimonial-img" alt="">
                  <h3>{{item.name}}</h3>
                  <h4>{{item.desigination}}</h4>
                  <p> {{item.details}} </p>
                </div>
              </ng-template> -->
  <!-- <ng-template carouselSlide>
                        <div class="testimonial-item">
                            <img src="assets/img/testimonial-2.jpg" class="testimonial-img" alt="">
                            <h3>Sara Wilsson</h3>
                            <h4>Designer</h4>
                            <p>
                              Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
                            </p>
                          </div>
                    </ng-template>  
                    <ng-template carouselSlide>
                        <div class="testimonial-item">
                            <img src="assets/img/testimonial-3.jpg" class="testimonial-img" alt="">
                            <h3>Jena Karlis</h3>
                            <h4>Store Owner</h4>
                            <p>
                              Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
                            </p>
                          </div>
                    </ng-template>   -->
  <!-- </owl-carousel-o>
          </div>

        </div>
      </div>


    </div>
  </section>#testimonials -->

  <!--==========================
      Team Section
    ============================-->
  <!-- <section id="team" *ngIf="!isVisible">
    <div class="container">
      <div class="section-header">
        <h3>{{team.heading}}</h3>
        <p>{{team.subHeading}}</p>
      </div>

      <div class="row">

        <div class="col-lg-3 col-md-6 wow fadeInUp" *ngFor="let item of team.grid" data-wow-delay="0.1s">
          <div class="member">
            <img [src]="item.image" class="img-fluid" alt="">
            <div class="member-info">
              <div class="member-info-content">
                <h4>{{item.name}}</h4>
                <span>{{item.desigination}}</span>
                <div class="social">
                  <a href=""><i class="fa fa-twitter"></i></a>
                  <a href=""><i class="fa fa-facebook"></i></a>
                  <a href=""><i class="fa fa-google-plus"></i></a>
                  <a href=""><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>#team -->

  <!--==========================
      Clients Section
    ============================-->
  <!-- <section id="clients" class="section-bg">

    <div class="container">

      <div class="section-header">
        <h3>{{client.heading}}</h3>
        <p>{{client.subHeading}}</p>
      </div>

      <div class="row no-gutters clients-wrap clearfix wow fadeInUp">

        <div class="col-lg-3 col-md-4 col-xs-6" *ngFor="let item of client.grid">
          <div class="client-logo">
            <img [src]="item.image" class="img-fluid" alt="">
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <!--==========================
      Contact Section
    ============================-->
  <section id="contact">
    <div class="container-fluid">

      <div class="section-header">
        <h3>Contact Us</h3>
      </div>

      <div class="row wow fadeInUp">

        <div class="col-lg-5">
          <div class="map mb-4 mb-lg-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d7779.475895639278!2d77.76340826831535!3d12.860194850286696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s%23148%2C%20first%20floor%2C%20S2%20Avani%20Layout%2C%20Sompuragate%2C%20Sarjapura%2C%20Bangalore!5e0!3m2!1sen!2sin!4v1618656263926!5m2!1sen!2sin"
              frameborder="0" style="border:0; width: 100%; height: 312px;" allowfullscreen></iframe>
          </div>
        </div>

        <div class="col-lg-7">
          <div class="row">
            <div class="col-md-5 info">
              <i class="ion-ios-location-outline"></i>
              <p>#148, first floor, S2 Avani Layout, B Hosahalli main road,Sompuragate, Sarjapura, Bangalore -562125</p>
            </div>
            <div class="col-md-4 info">
              <i class="ion-ios-email-outline"></i>
              <p>info@mithratechnologies.in</p>
            </div>
            <div class="col-md-3 info">
              <i class="ion-ios-telephone-outline"></i>
              <p>+91 99804 85643</p>
            </div>
          </div>

          <div class="form">
            <div id="sendmessage">Your message has been sent. Thank you!</div>
            <div id="errormessage"></div>
            <form action="" method="post" role="form" class="contactForm">
              <div class="form-row">
                <div class="form-group col-lg-6">
                  <input type="text" name="name" class="form-control" id="name" placeholder="Your Name"
                    data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                  <div class="validation"></div>
                </div>
                <div class="form-group col-lg-6">
                  <input type="email" class="form-control" name="email" id="email" placeholder="Your Email"
                    data-rule="email" data-msg="Please enter a valid email" />
                  <div class="validation"></div>
                </div>
              </div>
              <div class="form-group">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject"
                  data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                <div class="validation"></div>
              </div>
              <div class="form-group">
                <textarea class="form-control" name="message" rows="5" data-rule="required"
                  data-msg="Please write something for us" placeholder="Message"></textarea>
                <div class="validation"></div>
              </div>
              <div class="text-center"><button type="submit" title="Send Message">Send Message</button></div>
            </form>
          </div>
        </div>

      </div>

    </div>
  </section><!-- #contact -->

</main>