<section id="about-banner" class="clearfix">
    <div class="container">
        <div class="about-info">
            <h2>Services</h2>
            <h3>Staff Augmentation Services</h3>
        </div>
    </div>
</section>
<section id="about">
    <div class="container">
        <div class="row about-extra">
            <div class="col-lg-5 wow fadeInUp order-1 order-lg-2">
                <img src="assets/img/about-extra-1.svg" class="img-fluid" alt="">
            </div>
            <div class="col-lg-7 wow fadeInUp pt-4 pt-lg-0 order-2 order-lg-1">
                <h4>Staff Augmentation Services</h4>
                <p>
                    A dynamic manpower is an integral component of an organization that enables
                    it to achieve the targets &amp; goals. We, at Mithra understand it well, and thus,
                    offer an integrated staffing solution for our valued partners. .In our endeavour
                    to cater to our clients’ diverse manpower requirements, we identify the right
                    resources through our well evolved search and evaluation procedures. Our
                    varied offerings of DIRECT HIRE activity and TEMP or CONTRACT HIRE
                    requirements not only work for middle level positions, but our EXECUTIVE
                    SEARCH service also caters to the hiring needs for Top Level Executives.
                    <br><br>
                    It is observed many a times that finding ‘made to fit resources’ for the
                    requirement is very difficult, we train and provide resources through our HIRE-
                    TRAIN-DEPLOY service to cater to client needs. Our bouquet of offerings also
                    includes OUTSOURCING model of service. In addition, we also work in
                    partnership to manage your workforce with our MANAGED STAFFING
                    services.
                </p>

                <h5>Staffing for NBFC/Small Finance Banks</h5>
                <p>
                    Among the various Staffing models, we offer an emphasized and concerted
                    effort has been exhibited for Small finance banks/NBFC. As the business
                    demands and their operations are different from the other commercial banks, the
                    staffing requirement also has to be treated differently. Our specialized team
                    helps our various NBFC clients to meet their manpower requirement as and
                    when required.
                </p>
                <h5>Staffing for Logistics</h5>
                <p>
                    Supply chain management has been an integral part of the manufacturing
                    industry for ages. Over a period of time this support activity has evolved into a
                    driving force for a manufacturing industry as well as for many other industries.
                    This demands for personnel with specific qualifications and expertise. Our
                    contribution in this vertical empowered by our focused and dedicated team
                    striving to fulfill the emerging needs of our clients in the logistics departments
                    has been appreciated by all our clients.
                </p>
            </div>
        </div>
    </div>
</section>