<section id="about-banner" class="clearfix">
    <div class="container">
        <div class="about-info">
            <h2>Services</h2>
            <h3>Digital Marketing </h3>
        </div>
    </div>
</section>

<section id="service">
    <div class="container">
        <div class="row about-extra services">
            <div class="col-lg-6 wow fadeInUp order-1 order-lg-2">
                <img src="assets/img/digitalmarketing.jpg" class="img-fluid" alt="">
            </div>

            <div class="col-lg-6 wow fadeInUp pt-4 pt-lg-0 order-2 order-lg-1">
                <h4>Digital Marketing</h4>
                <p>
                    With the onset of the digital media, it has become next to impossible for
                    businesses to progress without adopting this medium, which has an expansive
                    and a targeted clientele reach. We at Mithra, with our high-level team with
                    proven expertise provide Digital Marketing services with end to end products.
                    Our Team with a bouquet of products, are equipped to provide an evolving
                    solution in this area. Our service comprises MEDIA PLANNING, MEDIA
                    MARKETING, RESEARCH &amp; ANALYTICS and much more.</p>
            </div>
        </div>
    </div>
</section>