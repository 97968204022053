<section id="about-banner" class="clearfix">
    <div class="container">
        <div class="about-info">
            <h2>Contact</h2>
            <h3></h3>
        </div>
    </div>
</section>

<section id="contact">
    <div class="container">
        <div class="row wow fadeInUp">

            <div class="col-lg-5">
                <div class="map mb-4 mb-lg-0">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d7779.475895639278!2d77.76340826831535!3d12.860194850286696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s%23148%2C%20first%20floor%2C%20S2%20Avani%20Layout%2C%20Sompuragate%2C%20Sarjapura%2C%20Bangalore!5e0!3m2!1sen!2sin!4v1618656263926!5m2!1sen!2sin"
                        frameborder="0" style="border:0; width: 100%; height: 412px;" allowfullscreen></iframe>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="row">
                    <div class="col-md-6 info">
                        <i class="ion-ios-location-outline"></i>
                        <p>#148, first floor, S2 Avani Layout, B Hosahalli main road,Sompuragate, Sarjapura, Bangalore
                            -562125
                        </p>
                    </div>
                    <div class="col-md-6 info">
                        <i class="ion-ios-email-outline"></i>
                        <p>info@mithratechnologies.in</p>

                        <i class="ion-ios-telephone-outline"></i>
                        <p>+91 99804 85643</p>
                    </div>

                </div>

                <div class="form">
                    <div id="sendmessage">Your message has been sent. Thank you!</div>
                    <div id="errormessage"></div>
                    <form action="" method="post" role="form" class="contactForm">
                        <div class="form-row">
                            <div class="form-group col-lg-6">
                                <input type="text" name="name" class="form-control" id="name" placeholder="Your Name"
                                    data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                                <div class="validation"></div>
                            </div>
                            <div class="form-group col-lg-6">
                                <input type="email" class="form-control" name="email" id="email"
                                    placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                                <div class="validation"></div>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject"
                                data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                            <div class="validation"></div>
                        </div>
                        <div class="form-group">
                            <textarea class="form-control" name="message" rows="5" data-rule="required"
                                data-msg="Please write something for us" placeholder="Message"></textarea>
                            <div class="validation"></div>
                        </div>
                        <div class="text-center"><button type="submit" title="Send Message">Send Message</button></div>
                    </form>
                </div>
            </div>

        </div>

    </div>
</section>