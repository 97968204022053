<section id="about-banner" class="clearfix">
  <div class="container">
    <div class="about-info">
      <h2>About US</h2>
      <h3>Your Sagacious Partners</h3>
      <h3>Formerly known as Mithra technologies</h3>

    </div>
  </div>
</section>

<section id="about">
  <div class="container">

    <header class="section-header wow fadeInUp">

      <h4>Standing at the crossroads in business expansion plans?</h4>
      <h4> Wish to hitch your wagon to the stars?</h4>
      <h4>Do not feel weighed down...</h4>
      <p>
        Come and partner with our discreet and accomplished team of consultants, with
        more than 20 years of experience from different verticals of service industry to
        share your load of planning and end to end executions. We have successfully
        executed bespoke research, consulting , staffing and marketing assignments
        across diverse industry segments for our Indian as well as international clients
        Over time, our core consultant group has catered to the needs of our clients in
        solving their complex, analytical key result areas transforming actions into
        results and building strategic business plans for immaculate unprecedented
        business growth.</p>
    </header>

    <div class="row d-flex align-items-center about-container" *ngFor="let about of aboutUs">

      <div class="col-lg-6 fadeInUp pt-5 pt-lg-0 content order-1">
        <!-- <h4>{{about.heading}}</h4> -->
        <!-- <p>
            {{about.ShortBreaf}}
          </p> -->

        <div class="icon-box wow fadeInUp" *ngFor="let tile of about.gridTile; let i = index" data-wow-delay="0.2s">
          <div class="icon" *ngIf="tile.icon"><i class="{{tile.icon}}"></i></div>
          <h4 class="title"><a href="">{{tile.title}}</a>
          </h4>
          <p class="description" *ngIf="tile.content">{{tile.content}}</p>
        </div>

      </div>

      <div class="col-lg-6  background  wow fadeInUp">
        <img [src]="about.imageUrl" class="img-fluid" alt="">
      </div>

    </div>

    <div class="row about-extra">
      <div class="col-lg-4 wow fadeInUp">
        <img src="assets/img/about-img.png" class="img-fluid" alt="">
      </div>
      <div class="col-lg-8 wow fadeInUp pt-5 pt-lg-0">
        <h4>Inception</h4>
        <p>
          Mithra is founded by professionals with diverse backgrounds and exposure to
          wide industry verticals and technology practices. We influence our endowed
          manpower to provide sourcing solutions to our clients. Mithra is in the business
          of sourcing qualified, experienced human resources from leading companies to
          help them achieve competitive advantage, with excellent experience in
          recruiting qualified professionals for various industries
        </p>
        <h4>Our Vision</h4>
        <p>
          Our employees have to be recognized, trained, empowered &amp; supported to an
          extent that our Valuable Customers are delivered more value in terms of
          performance resulting in effective utilization of the resource and lowering the
          attrition rate.
        </p>

        <h4>Our Mission</h4>
        <p>
          Our mission is to provide our clients complete one-stop recruitment and staffing
          solutions to all sectors of industry so as to sustain the competitive advantages.
          We help the organization to attract, retain and produce the best talent pool. We
          build relationships with our clients to fully and continually identify and
          understand their needs.
        </p>
      </div>
    </div>
    <!-- 
    <div class="row about-extra">
      <div class="col-lg-6 wow fadeInUp order-1 order-lg-2">
        <img src="assets/img/about-extra-2.svg" class="img-fluid" alt="">
      </div>

      <div class="col-lg-6 wow fadeInUp pt-4 pt-lg-0 order-2 order-lg-1">
        <h4>Inception</h4>
        <p>

          Mithra is founded by professionals with diverse backgrounds and exposure to wide industry verticals and
          technology
          practices. We influence our endowed manpower to provide sourcing solutions to our clients. Mithra is in the
          business of
          sourcing qualified, experienced human resources from leading companies to help them achieve competitive
          advantage, with
          excellent experience in recruiting qualified professionals for various industries
        </p>
        <p>
          Voluptas saepe natus quidem blanditiis. Non sunt impedit voluptas mollitia beatae. Qui esse molestias.
          Laudantium libero nisi vitae debitis. Dolorem cupiditate est perferendis iusto.
        </p>
        <p>
          Eum quia in. Magni quas ipsum a. Quis ex voluptatem inventore sint quia modi. Numquam est aut fuga mollitia
          exercitationem nam accusantium provident quia.
        </p>
      </div>

    </div> -->

  </div>
</section>