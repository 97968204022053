<section id="about-banner" class="clearfix">
    <div class="container">
        <div class="about-info">
            <h2>Services</h2>
            <h3>IT Professional Service</h3>
        </div>
    </div>
</section>

<section id="service">
    <div class="container">
        <div class="row about-extra services">
            <div class="col-lg-5 wow fadeInUp order-1 order-lg-2">
                <img src="assets/img/about-extra-2.svg" class="img-fluid" alt="">
            </div>

            <div class=" col-lg-7 wow fadeInUp pt-4 pt-lg-0 order-2 order-lg-1">
                <h4>IT Professional Service</h4>
                <p>
                    To ensure a business runs smoothly and remains ahead of the competition, one
                    has to adapt to trending and new-gen technologies. Trending technologies such
                    as <i>Mobile, Analytics, Cloud, IoT</i> and so on, makes it imperative to not just apply
                    them but also to make timely upgrades and updates in the organisational system
                    to ensure optimum growth. Our highly skilled managerial service team helps

                    you take care of your business seamlessly and cost-effective manner to optimize
                    business processes, keeping in control the risk and cost.<br><br>
                </p>
                <h4>Mitra Infrastructure Service and Support (MISS)</h4>
                <p>Our competent team of experienced professionals includes a comprehensive and
                    an integrated group, who can manage an Enterprise’s Hybrid IT environment.
                    Entrust your business to experts, the MISS team, and they will ensure you miss
                    the nuisances of handling the hybrid environment and focus more on your
                    balance sheet.</p>
            </div>
        </div>
    </div>
</section>